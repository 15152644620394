import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

const GenerateModal = ({ name, position, email }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const buttonStyle = {
    backgroundColor: '#0077B5', // LinkedIn blue color
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    textDecoration: 'none', // Remove default underline
  };

  return (
    <Modal
      trigger={
        <Button onClick={handleOpen} color='teal'>
          Contact Me
        </Button>
      }
      open={isOpen}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header>{name}</Modal.Header>
      <Modal.Content>
      
        <a href={email} target="_blank" rel="noopener noreferrer">
          <button style={buttonStyle}>
            Connect with Me!
          </button>
        </a>
      
        
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose} negative>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default GenerateModal;
