import React from 'react';
import { FaLinkedin, FaInstagram } from 'react-icons/fa'; // Import the icons from react-icons
import './Footer.css'; // Import your CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <h1 className='words'>Connect with Us</h1>
      <div className="social-icons">
        <a href="https://www.linkedin.com/company/girlswhocodeuf/mycompany/">
          <FaLinkedin className="social-icon" />
        </a>
        <a href="https://www.instagram.com/girlswhocodeuf?igsh=NmluNGY0aHhjNGU1">
          <FaInstagram className="social-icon" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
