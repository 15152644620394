import React from 'react'

const Login = () => {

  return (
    <>
     <div style={{
      color: 'white'
    }}><h1>Login Page Coming Soon!!</h1> 
    </div>
    </>
    
  )
}

export default Login

