import React from 'react'
import "./About.css"
import Footer from "../../components/Footer"
import TablineImage1 from "../../media/event_pictures/tabling/tabling1.jpg";
import TablineImage2 from "../../media/event_pictures/tabling/tabling2.jpg";
import TablineImage3 from "../../media/event_pictures/tabling/tabling3.jpg";
import TablineImage4 from "../../media/event_pictures/tabling/tabling4.jpg";
import gbmImage1 from "../../media/event_pictures/gbm/gbm1.jpg";
import gbmImage2 from "../../media/event_pictures/gbm/gbm2.jpg";
import gbmImage3 from "../../media/event_pictures/gbm/gbm3.jpg";
import gbmImage4 from "../../media/event_pictures/gbm/gbm4.jpg";
import careerImage1 from "../../media/event_pictures/career_events/career1.jpg";
import careerImage2 from "../../media/event_pictures/career_events/career2.jpg";
import careerImage3 from "../../media/event_pictures/career_events/career3.jpg";
import careerImage4 from "../../media/event_pictures/career_events/career4.jpg";
import socialImage1 from "../../media/event_pictures/social/social1.jpg";
import socialImage2 from "../../media/event_pictures/social/social2.jpg";
import socialImage3 from "../../media/event_pictures/social/social3.jpg";
import socialImage4 from "../../media/event_pictures/social/social4.jpg";


const About = () => {

  return (
    <div className="about-container">
      <h1>You can find us at......</h1>
      <h1 className = "title">Tabling!</h1>
      <div className="picture-container">
        <div className="row">
          <img src= {TablineImage1} alt="Placeholder" className='table-picture'/>
          <img src= {TablineImage2} alt="Placeholder" className='table-picture'/>
          <img src= {TablineImage3} alt="Placeholder" className='table-picture'/>
          <img src= {TablineImage4} alt="Placeholder" className='table-picture'/>
        </div>
      </div>
      <h1>General Body Meeting!</h1>
      <div className="picture-container">
        <div className="row">
          <img src= {gbmImage1} alt="Placeholder" className='table-picture'/>
          <img src= {gbmImage2} alt="Placeholder" className='table-picture'/>
          <img src= {gbmImage3} alt="Placeholder" className='table-picture'/>
          <img src= {gbmImage4} alt="Placeholder" className='table-picture'/>
        </div>
      </div>
      <h1>Career Fairs!</h1>
      <div className="picture-container">
        <div className="row">
          <img src= {careerImage1} alt="Placeholder" className='table-picture'/>
          <img src= {careerImage2} alt="Placeholder" className='table-picture'/>
          <img src= {careerImage3} alt="Placeholder" className='table-picture'/>
          <img src= {careerImage4} alt="Placeholder" className='table-picture'/>
        </div>
      </div>
      <h1>Fun Social Events!</h1>
      <div className="picture-container">
        <div className="row">
          <img src= {socialImage1} alt="Placeholder" className='table-picture'/>
          <img src= {socialImage2} alt="Placeholder" className='table-picture'/>
          <img src= {socialImage3} alt="Placeholder" className='table-picture'/>
          <img src= {socialImage4} alt="Placeholder" className='table-picture'/>
        </div>
      </div>
    <Footer/>
    </div>
  );
};

export default About;

