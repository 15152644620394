import ShaliniImage from "../officerImages/ShaliniNannapaneni_Communications.jpeg"
import AnnikaImage from "../officerImages/AnnikaSingh_Communications.jpeg"
import DanielaImage from "../officerImages/DanielaIturres_GraphicDesign.jpeg"
import DishaImage from "../officerImages/DishaGaonkar_OrganizationAffairs.jpeg"
import SanjanaImage from "../officerImages/Sanjana Imandi - Membership (Recruitment).jpg"
import AayeshaImage from "../officerImages/AayeshaIslam_Membership(StudyRoomLead).jpg"
import ArmeetImage from "../officerImages/ArmeetGahra_AssistantMentorshipLogistics.jpg"
import AparnaImage from "../officerImages/socialchair.jpg"
import PaulinaImage from "../officerImages/PaulinaGonzalez-Brito_SocialExternal.jpeg"
import JillianImage from "../officerImages/JillianArmstrong_Fundraiser.jpeg"
import CarolinaBImage from "../officerImages/CarolinaBoitel_Sponsorship.jpg"
import CarolinaAImage from "../officerImages/CarolinaAranguizDias_CommunityOutreach.jpeg"
import JulianaImage from "../officerImages/JulianaLucas_Workshop(Series).jpeg"

import BoardCards from "./BoardCards";

function ChairCards() {
  return (
    <>
      {BoardCards(
        ShaliniImage,
        "Shalini Nannapaneni",
        "Communications",
        "https://www.linkedin.com/in/shalini-nannapaneni"
      )}
      {BoardCards(
        AnnikaImage,
        "Annika Singh",
        "Communications",
        "https://www.linkedin.com/in/annika-singh-02083922a/"
      )}
      {BoardCards(
        DanielaImage,
        "Daniela Iturres",
        "Graphic Design",
        "https://www.linkedin.com/in/danielaiturres/"
      )}
      {BoardCards(
        DishaImage,
        "Disha Gaonkar",
        "Organization Affairs",
        "https://www.linkedin.com/in/disha-gaonkar/"
      )}
      {BoardCards(
        SanjanaImage,
        "Sanjana Imandi",
        "Membership (Recruitment) ",
        "https://www.linkedin.com/in/sanjana-imandi-097156207/"
      )}
      {BoardCards(
        AayeshaImage,
        "Aayesha Islam",
        "Membership (Study Room Lead)",
        "https://www.linkedin.com/in/aayeshamislam/"
      )}
      {BoardCards(
        ArmeetImage, 
        "Armeet Kaur Gahra", 
        "Assistant Mentorship (Logistics)", 
        "https://www.linkedin.com/in/armeet-kaur-gahra/"
      )}
      {BoardCards(
        AparnaImage,
        "Aparna Nimmagadda",
        "Social Internal",
        "https://www.linkedin.com/in/aparnanimmagad/"
      )}
      {BoardCards(
        PaulinaImage,
        "Paulina Gonzalez-Brito",
        "Social External",
        "https://www.linkedin.com/in/paulina-gonzalez-brito-71a3b526b/"
      )}
      {BoardCards(
        JillianImage,
        "Jillian Armstrong",
        "Fundraise",
        "https://www.linkedin.com/in/jillian-armstrong-91118aa4/"
      )}
      {BoardCards(
        CarolinaBImage,
        "Carolina Boitel",
        "Sponsorship ",
        "https://www.linkedin.com/in/carolina-boitel/"
      )}
      {BoardCards(
        CarolinaAImage,
        "Carolina Aranguiz Dias",
        "Community Outreach",
        "https://www.linkedin.com/in/carolinaaranguizdias/"
      )}
      {BoardCards(
        JulianaImage,
        "Juliana Lucas",
        "Workshop (Series)",
        "https://www.linkedin.com/in/juliana-lucas/"
      )}
    </>
  );
}

export default ChairCards;
