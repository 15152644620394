import BoardCards from "./BoardCards";
import MuningImage from "../officerImages/MuningChen_ScrumMaster.jpeg"
import RutviImage from "../officerImages/RutviShukla_ProjectManager.jpg"
import AyahImage from "../officerImages/AyahSaleh_SoftwareDirector.jpg"
function WebTeamCards() {
  return (
    <>
      {BoardCards(
        MuningImage,
        "Muning Chen",
        "Scrum Master",
        "https://www.linkedin.com/in/muning-chen-003760217/"
      )}
      {BoardCards(
        RutviImage,
        "Rutvi Shukla",
        "Project Manager",
        "https://www.linkedin.com/in/rutvi-shukla/"
      )}
      {BoardCards(
        AyahImage,
        "Ayah Saleh ",
        "Software Director (OpenSource)",
        "https://www.linkedin.com/in/ayah-saleh/"
      )}
    </>
  );
}

export default WebTeamCards;
