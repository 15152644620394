import BoardCards from "./BoardCards";
import PresidentImage from "../officerImages/president.jpg"
import VPIImage from "../officerImages/VPinternal.jpg"
import VPEImage from "../officerImages/VPexternal.jpg"
import TreasuereImage from "../officerImages/treasurer.jpg"
import SecretaryImage from "../officerImages/secretary.jpg"

function ExecCards() {
  return (
    <>
      {BoardCards(
        PresidentImage,
        "Victoria De Alba",
        "President",
        "girlswhocodeuf@gmail.com"
      )}

      {BoardCards(
        VPEImage,
        "Elisa Arenas",
        "Vice-President External",
        "girlswhocodeuf@gmail.com"
      )}
      {BoardCards(
        VPIImage,
        "Sophia Keane",
        "Vice-President Internal",
        "girlswhocodeuf@gmail.com"
      )}

      {BoardCards(
        SecretaryImage,
        "Tanya Charan",
        "Secretary",
        "girlswhocodeuf@gmail.com"
      )}

        {BoardCards(
        TreasuereImage,
        "Nandika Regatti",
        "Treasurer",
        "maiagervasone@ufl.edu"
      )}
    </>
  );
}

export default ExecCards;
