import React from 'react'
import Footer from "../../components/Footer"
import './Calendar.css';

const Calendar = () => {

  return (
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FNew_York&bgcolor=%23ffffff&title=Girls%20Who%20Code%20Event%20Calendar&src=bXVuaW5nLmNoZW5AdWZsLmVkdQ&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=Z2lybHN3aG9jb2RldWZAZ21haWwuY29t&color=%23039BE5&color=%2333B679&color=%23F4511E"
        frameBorder="0"
        scrolling="no"
        title="Google Calendar"
      ></iframe>  
    
  )
};

export default Calendar;

